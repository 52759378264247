<template>
  <div class="flex items-center justify-between gap-2 pb-4 md:pb-5">
    <div class="flex items-center gap-2 w-full">
      <VBtn
        icon="fa-regular fa-bars"
        class="flex xl:hidden"
        size="27"
        aria-label="Show tabs sidebar"
        @click="handleCLick"
      >
      </VBtn>
      <SearchBar class="flex-grow-1" />
    </div>
    <div
      class="flex gap-1"
      role="listbox"
      aria-label="Select view option"
      :class="{ 'opacity-30 pointer-events-none': disabled }"
    >
      <VTooltip
        id="Grid switcher"
        :aria-label="i18n.t('view_options.grid')"
        location="top"
      >
        <template #activator="{ props }">
          <VBtn
            v-bind="props"
            class="view-switcher-btn"
            icon="fa-light fa-grid-2"
            value="grid"
            :data-test-id="`grid-view`"
            size="small"
            :active="viewOption === 'grid'"
            rounded="lg"
            :aria-label="i18n.t('view_options.grid')"
            role="option"
            @click="handleViewChange('grid')"
          ></VBtn>
        </template>
        {{ i18n.t('view_options.grid') }}
      </VTooltip>

      <VTooltip
        id="List switcher"
        :aria-label="i18n.t('view_options.list')"
        location="top"
      >
        <template #activator="{ props }">
          <VBtn
            v-bind="props"
            class="view-switcher-btn"
            icon="fa-light fa-diagram-cells"
            value="list"
            :data-test-id="`list-view`"
            size="small"
            :active="viewOption === 'list'"
            rounded="lg"
            :aria-label="i18n.t('view_options.list')"
            role="option"
            @click="handleViewChange('list')"
          ></VBtn>
        </template>
        {{ i18n.t('view_options.list') }}
      </VTooltip>
    </div>
  </div>
</template>

<script setup lang="ts">
import { usePreferencesStore } from '@/stores/deepadmin/preferences.ts';
import { onBeforeMount, ref } from 'vue';
import { useEmitter } from '@/composables/useEmitter';
import { useI18n } from 'vue-i18n';

const preferencesStore = usePreferencesStore();
const emitter = useEmitter();
defineProps({
  disabled: {
    type: Boolean,
    default: false
  }
});

const viewOption = ref('grid');
const i18n = useI18n();
function handleViewChange(newValue: 'grid' | 'list') {
  preferencesStore.setPreference('viewOption', newValue);
  viewOption.value = newValue;
  emitter.$emit('change-view-preference', newValue);
}

function handleCLick() {
  emitter.$emit('toggle-sidebar');
}

onBeforeMount(() => {
  viewOption.value =
    (preferencesStore!.preferences?.viewOption as string) || 'grid';
  emitter.$emit('change-view-preference', viewOption.value);
});
</script>

<style lang="scss" scoped>
.view-switcher-btn.v-btn--active :deep(.v-btn__overlay) {
  @apply opacity-0;
}

.view-switcher-btn.v-btn--active {
  @apply bg-blue-400/15 text-primary;
}
</style>
