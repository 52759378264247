<template>
  <div class="document-sign-type" data-test-id="sign-type">
    <img
      alt="Pen Logo"
      class="sign-icon"
      height="20"
      src="/svg/sign_icon_grey.svg"
    />
    <span>
      {{ signatureLevel }}
    </span>
    <v-menu location="left top" max-width="300" open-on-hover open-on-click>
      <template #activator="{ props: ActivatorProps }">
        <VIcon
          v-bind="ActivatorProps"
          class="menu-icon"
          :size="14"
          aria-label="Signature level hint"
        >
          fa-regular fa-circle-info
        </VIcon>
      </template>
      <v-card>
        <v-card-text class="signature-level-description">
          {{ signatureLevelDescription }}
        </v-card-text>
      </v-card>
    </v-menu>
  </div>
</template>

<script lang="ts" setup>
import {
  getJurisdictionText,
  getSignatureLevelAcronym,
  getSignatureLevelText
} from '@/composables/useUtils';
import type { Jurisdiction } from '@/types/enums/Jurisdiction';
import { SignatureMode } from '@/types/enums/SignatureMode';
import { computed, type PropType } from 'vue';
import { useI18n } from 'vue-i18n';

const i18n = useI18n();
const props = defineProps({
  signatureMode: {
    type: String as PropType<SignatureMode>,
    default: undefined
  },
  jurisdiction: {
    type: String as PropType<Jurisdiction>,
    default: undefined
  }
});

const signatureLevel = computed(() => {
  if (props.signatureMode) {
    if (props.signatureMode === SignatureMode.TIMESTAMP) {
      getSignatureLevelAcronym(props.signatureMode);
    }
    const signatureLevelAcronym = getSignatureLevelAcronym(props.signatureMode);
    return signatureLevelAcronym === 'SES'
      ? signatureLevelAcronym
      : signatureLevelAcronym +
          ' · ' +
          i18n.t(getJurisdictionText(props.jurisdiction));
  }
  return undefined;
});

const signatureLevelDescription = computed(() => {
  if (props.signatureMode) {
    const key = getSignatureLevelText(props.signatureMode);
    return i18n.t(key);
  } else {
    return undefined;
  }
});
</script>

<style lang="scss">
@import '@/styles/core/colors';

.document-sign-type {
  display: flex;
  gap: 0.5rem;
  align-items: center;

  span {
    color: $grey;
    font-weight: 400 !important;
    margin-right: 0.25rem;
    font-size: 14px !important;
  }
  .menu-icon {
    color: $grey;
    padding-top: 0px;
  }

  .signature-level-description {
    padding: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    letter-spacing: 0.004em;
  }
}
</style>
