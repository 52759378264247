<template>
  <v-expansion-panel
    focusable
    class="invited-signatories-expansion-panel"
    @click="scroll"
  >
    <v-expansion-panel-title
      class="font-medium"
      data-test-id="invited-signatories-expansion-panel"
    >
      {{
        hasApprovers
          ? i18n.t('titles.invited_persons')
          : i18n.t('titles.invited_signatories')
      }}
      <template #actions="{ expanded }">
        <VIcon :size="12" :class="{ 'rotate-180': expanded }">
          fa-regular fa-chevron-down
        </VIcon>
      </template>
    </v-expansion-panel-title>
    <!-- UNORDERED SIGNEES -->
    <v-expansion-panel-text
      v-if="currentDocument?.signeesOrdered.length === 1"
      data-test-id="signees-unordered"
    >
      <div class="grid gap-6">
        <template
          v-for="signee in currentDocument?.signees"
          :key="signee.signeeId"
        >
          <InvitedSignee :signature="signee" />
        </template>
      </div>
    </v-expansion-panel-text>
    <!-- /UNORDERED SIGNEES -->

    <!-- ORDERED -->
    <v-expansion-panel-text
      v-else-if="currentDocument && currentDocument.signeesOrdered"
      style="padding-left: 0 !important"
      data-test-id="signees-ordered"
    >
      <div class="grid gap-3">
        <div
          v-for="(group, groupIndex) in currentDocument.signeesOrdered.filter(
            (_group) => _group.length > 0
          )"
          :key="groupIndex"
          class="bg-gray-50 border border-gray-300 rounded flex items-center py-5 px-3 gap-4"
          :data-test-id="`signer-${groupIndex + 1}`"
        >
          <div
            class="h-8 w-9 rounded-full text-base bg-blue-100 text-blue-700 flex items-center justify-center"
            :data-test-id="`signers-order-${groupIndex + 1}`"
          >
            {{ groupIndex + 1 }}
          </div>
          <div class="w-full grid gap-6">
            <InvitedSignee
              v-for="signee in group"
              :key="signee.signeeId"
              :signature="signee"
              :has-approvers="hasApprovers"
            />
          </div>
        </div>
      </div>
    </v-expansion-panel-text>
    <!-- /ORDERED -->
  </v-expansion-panel>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { useDocumentContext } from '@/composables/useDocumentContext';
import { SigneeType } from '@/types/enums/SigneeType.ts';
import { computed } from 'vue';
import type { Signee } from '@/types/Signee.ts';

const i18n = useI18n();
const { currentDocument } = useDocumentContext();

const hasApprovers = computed(() =>
  currentDocument?.value?.signeesOrdered
    .flat()
    .some((invitee: Signee) => invitee.signeeType === SigneeType.APPROVE)
);
function scroll() {
  setTimeout(() => {
    const documentInfo = document.getElementById('document-info');
    if (documentInfo) {
      documentInfo.scrollIntoView({
        block: 'nearest',
        inline: 'nearest',
        behavior: 'smooth'
      });
    }
  }, 200);
}
</script>

<style scoped lang="scss">
.invited-signatories-expansion-panel
  :deep(.v-expansion-panel-title--focusable) {
  @apply focus-visible:ring-[0.5px] focus-visible:ring-primary;
}
</style>
