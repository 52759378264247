import { constants } from '@/constants/constants';
import { pixelsToPoints, pointsToPixels } from '@/services/pdfService';
import { overlaps } from '@/services/styleService';
import { DocumentStatus } from '@/types/enums/DocumentStatus';
import { SignStatus } from '@/types/enums/SignStatus';
import { useSignStore } from '@/stores/sign/sign';
import { orderBy } from '@/composables/useUtils';
import type { Document } from '@/types/Document';
import type { Signee } from '@/types/Signee';
import type { OverviewDocument } from '@/stores/overview/overview';
import type { AutographPosition } from '@/types/AutographPosition';
import type { PageLimits } from '@/types/PageLimits';
import type {
  PageRatio,
  PlaceSignatureOptions,
  ResizableHTMLElement,
  ScreenPosition,
  SignatureDimensions,
  SignatureOffset
} from '@/types/ui';
import type { DropEvent } from '@/utils/types/DropEvent';
import type { SignaturePosition } from '@/types/SignaturePosition';
import type { SignSignee } from '@/types/SignSignee';
import { clamp } from './utilsService';
import { SigneeType } from '@/types/enums/SigneeType';
import { useCreateStore } from '@/stores/create.ts';
import type { RouteRecordNameGeneric } from 'vue-router';

/* eslint-disable @typescript-eslint/no-explicit-any */
interface PDFPageProxy {
  [key: string]: any;
}

class SignatureService {
  canSign = (email: string, document: Document): boolean =>
    (document?.signees?.some(
      (signee) =>
        this.compareString(signee.email, email) &&
        signee.signStatus !== SignStatus.SIGNED &&
        signee.signeeType === SigneeType.SIGN &&
        signee.signStatus !== SignStatus.ON_HOLD
    ) &&
      document.documentStatus === DocumentStatus.IN_PROGRESS) ??
    false;

  canApprove = (email: string, document: Document): boolean =>
    (document?.signeesOrdered
      .flat()
      ?.some(
        (signee) =>
          this.compareString(signee.email, email) &&
          signee.signeeType === SigneeType.APPROVE &&
          (signee.signStatus === SignStatus.PENDING ||
            signee.signStatus === SignStatus.ON_HOLD)
      ) &&
      document.documentStatus === DocumentStatus.IN_PROGRESS) ??
    false;

  isSignedByUser = (userEmail: string, signees: Signee[]): boolean =>
    signees
      .filter((signee) => this.compareString(signee.email, userEmail))
      .every((userSignature) => userSignature.signStatus === SignStatus.SIGNED);

  isSignedByUserFromOverview = (document: OverviewDocument) =>
    document?.signStatus === SignStatus.SIGNED;

  shouldISign = (userEmail: string, signees: Signee[]) =>
    !!signees.find((signee) => this.compareString(signee.email, userEmail));

  shouldISignFromOverview = (overviewDocument: OverviewDocument) =>
    overviewDocument?.signStatus === SignStatus.PENDING;

  getSignatureByEmail = (userEmail: string, signatures: Signee[]) =>
    signatures.find((signature) =>
      this.compareString(signature.email, userEmail)
    );

  scrollToSignaturePosition(autographPosition: AutographPosition) {
    if (!autographPosition) {
      return;
    }

    const documentPreview = document.getElementById('page-content');
    const currentPage = document.getElementById(
      `id-${autographPosition.pageNumber}`
    );

    if (!currentPage || !documentPreview) {
      return;
    }

    const heightRatio = currentPage.clientHeight / 842;
    const positionY = Math.round(
      currentPage.clientHeight - autographPosition.y * heightRatio
    );
    const halfViewportHeight = window.innerHeight / 2;

    documentPreview.scroll({
      top:
        currentPage.offsetTop +
        positionY -
        autographPosition.height * heightRatio -
        halfViewportHeight,
      behavior: 'smooth'
    });
  }

  scrollToPage(pageNumber: number) {
    const documentPreview = document.getElementById('page-content');
    const currentPage = document.getElementById(`id-${pageNumber}`);

    if (!documentPreview || !currentPage) {
      return;
    }

    documentPreview.scroll({
      top: currentPage.offsetTop,
      behavior: 'smooth'
    });
  }

  getPageLimits = (
    pageNumber: number,
    pageNodes: NodeListOf<ChildNode> | Array<ChildNode>
  ) => {
    const currentPage = pageNodes[pageNumber - 1];

    if (currentPage instanceof HTMLElement) {
      return {
        top: currentPage.offsetTop,
        right: currentPage.clientWidth,
        bottom: currentPage.offsetTop + currentPage.clientHeight,
        left: currentPage.offsetLeft
      };
    }
    throw new Error('Error getting page limits');
  };

  removeHandwrittenSignature = (
    userSigneeId: string,
    handwrittenSignatureData: string
  ) => {
    const signatureElementId = `signature-preview-${userSigneeId}`;
    const signatureElement = document.getElementById(signatureElementId);

    if (signatureElement instanceof HTMLElement) {
      // Update the background image and size
      signatureElement.style.backgroundImage = `url('${handwrittenSignatureData}')`;
      signatureElement.style.backgroundSize = 'contain';
    } else {
      console.error(`Element with ID ${signatureElementId} not found.`);
    }
  };

  getPageRatio(pageDetails: PDFPageProxy, currentPage: Element) {
    const pageDimensions = this.getPageDetails(pageDetails);
    const widthRatio = currentPage.clientWidth / pageDimensions.width;
    const heightRatio = currentPage.clientHeight / pageDimensions.height;

    return {
      widthRatio,
      heightRatio
    };
  }

  getPageDetails(pageDetails: PDFPageProxy) {
    const { rotate, view } = pageDetails;

    // Determine dimensions based on rotation
    const [widthIndex, heightIndex] =
      rotate === 90 || rotate === 270 ? [3, 2] : [2, 3];

    return {
      width: pointsToPixels(view[widthIndex]),
      height: pointsToPixels(view[heightIndex])
    };
  }

  getSignatureScreenPosition(
    pageLimits: PageLimits,
    currentPage: Element,
    pageDetails: PDFPageProxy,
    signee: Signee | SignSignee
  ): ScreenPosition {
    if (!signee.autographPosition) {
      throw new Error(
        'Error getting screen position: autographPosition undefined'
      );
    }

    const { heightRatio, widthRatio } = this.getPageRatio(
      pageDetails,
      currentPage
    );
    const { x, y, width, height } = signee.autographPosition;

    const signatureX = pointsToPixels(x) * widthRatio;
    const signatureY =
      pageLimits.bottom -
      pointsToPixels(height) * heightRatio -
      pointsToPixels(y) * heightRatio;
    const signatureWidth = pointsToPixels(width) * widthRatio;
    const signatureHeight = pointsToPixels(height) * heightRatio;

    return {
      left: `${signatureX}px`,
      top: `${signatureY}px`,
      height: `${signatureHeight}px`,
      width: `${signatureWidth}px`
    };
  }

  addSignaturePlaceholdersToDocumentPreview(
    signeeId: string,
    signees: Signee[],
    newPageDetails: PDFPageProxy,
    pageDetailsList: PDFPageProxy[]
  ) {
    signees.forEach(async (signee, index) => {
      if (
        signee.autographPosition &&
        signee.autographPosition?.height > 0 &&
        signee.signStatus !== SignStatus.SIGNED
      ) {
        await this.addSignaturePlaceholderToDocumentPreview(
          signeeId,
          signee,
          pageDetailsList[index] || newPageDetails
        );
      }
    });
  }

  setDefaultAutographImage(signeeId: string, defaultAutographUrl: string) {
    const signature = document.getElementById(`signature-preview-${signeeId}`);
    if (signature) {
      signature.style.backgroundImage = `url('${defaultAutographUrl}')`;
      signature.style.backgroundSize = 'contain';
    }
  }

  getSigneeByEmail = (email: string, signees: Signee[]) => {
    return signees.find((signee) => this.compareString(signee.email, email));
  };

  getSigneeBySigneeId = (signeeId: string, signees: Signee[]) => {
    return signees?.find((signee) => signee.signeeId === signeeId);
  };

  onlyMe(userEmail: string, document: Document) {
    return !!(
      document?.signeesOrdered &&
      this.getSigneeByEmail(userEmail, document.signeesOrdered.flat())
    );
  }

  async placeSignature(
    event: DropEvent,
    element: HTMLElement,
    placedSignatures: Signee[] | SignSignee[],
    pageDetails: PDFPageProxy,
    signatureOffsets: SignatureOffset[],
    page?: number
  ) {
    const signeeId = event.dataTransfer?.getData('text');
    const parent = element.parentNode;
    const signature = document.getElementById(`signature-preview-${signeeId}`);

    if (!signature) {
      console.error(
        `signature-preview-${signeeId} not found, cannot place signature`
      );
      return;
    }

    const currentPageNumber = page;

    if (!parent) {
      throw new Error('Error placing signature, element has no parent');
    }

    const pdfPreviewElement = document.getElementById('pdf-preview');
    const pageNodes =
      pdfPreviewElement?.getElementsByClassName('pageplaceholder') || [];

    const pageLimits = this.getPageLimits(currentPageNumber, pageNodes);

    const signatureOffset = this.getSignatureOffset(signeeId, signatureOffsets);
    if (!signatureOffset) {
      return;
    }

    const currentPage = pageNodes[currentPageNumber - 1];
    const MARGIN_BOTTOM = 16;
    // we need to take the page count of pages and the margin bottom into the equation as the layerY coordinates are taken relative to the current page and thus are not usable
    let pageHeight = 0;
    for (let i = 0; i <= pageNodes.length; i++) {
      if (i === currentPageNumber - 1) {
        break;
      }
      pageHeight += pageNodes[i].getBoundingClientRect().height + MARGIN_BOTTOM;
    }
    let positionX = event.layerX - signatureOffset?.offsetX;
    let positionY = event.layerY + pageHeight - signatureOffset?.offsetY;

    const pageRatio = this.getPageRatio(pageDetails, currentPage);
    // If placed for the first time set default M size
    if (
      !placedSignatures.find(
        (placedSignature) => placedSignature.signeeId === signeeId
      )
    ) {
      signature.style.width =
        constants.SIGNATURE_SIZE.M.WIDTH * pageRatio.widthRatio + 'px';
      signature.style.height =
        constants.SIGNATURE_SIZE.M.HEIGHT * pageRatio.heightRatio + 'px';
    }

    // adjust to not be outside the page X axis
    if (positionX < pageLimits.left) {
      positionX = pageLimits.left;
    } else if (positionX > pageLimits.right - signature.clientWidth) {
      positionX = pageLimits.right - signature.clientWidth;
    }

    // adjust to not be outside the page Y axis
    if (positionY < pageLimits.top) {
      positionY = pageLimits.top;
    } else if (positionY + signature.clientHeight > pageLimits.bottom) {
      positionY = pageLimits.bottom - signature.clientHeight;
    }

    signature.style.position = 'absolute';
    signature.style.left = `${positionX}px`;
    signature.style.top = `${positionY}px`;

    parent.appendChild(signature);

    await this.makeSignatureResizable(signeeId, currentPageNumber, pageDetails);

    const signStore = useSignStore();
    signStore.signaturePlaced = true;

    const signaturePosition: SignaturePosition = {};

    signaturePosition.id = signeeId;
    signaturePosition.page = currentPageNumber;
    signaturePosition.positionX = positionX / pageRatio.widthRatio;
    signaturePosition.positionY =
      (pageLimits.top + currentPage.clientHeight - positionY) /
        pageRatio.heightRatio -
      signature.clientHeight / pageRatio.heightRatio;
    signaturePosition.signatureHeight =
      signature.clientHeight / pageRatio.heightRatio;
    signaturePosition.signatureWidth =
      signature.clientWidth / pageRatio.widthRatio;
    return signaturePosition;
  }

  getSignatureOffset(email: string, signatureOffsets: SignatureOffset[]) {
    return signatureOffsets.find(
      (offset) => offset.id.toLowerCase() === email?.toLowerCase()
    );
  }

  async placeSignaturesAutomatically(
    signeeId: string,
    signees: Signee[],
    setSignaturePosition: (
      signaturePosition: SignaturePosition
    ) => Promise<void>,
    newPageDetails: PDFPageProxy,
    routeName: RouteRecordNameGeneric,
    currentPageNumber = 1
  ) {
    const signaturesToPlace = signees.filter(
      (signee) => signee.autographPosition === null
    );

    const pageDimensions = this.getPageDetails(newPageDetails);
    const pdfPreview = document.getElementById('pdf-preview');

    if (!pdfPreview) {
      throw new Error('Error placing signatures, pdf preview not found');
    }
    const pageNodes = pdfPreview.getElementsByClassName('pageplaceholder');

    const currentPage = pageNodes[0];
    const pageRatio = this.getPageRatio(newPageDetails, currentPage);
    const pageLimits = this.getPageLimits(currentPageNumber, pageNodes);
    const isSigningContext =
      routeName === 'sign-id' ||
      routeName === 'document-sign' ||
      routeName === 'document-seal';

    const signatureMargin = 32;
    const signatureHeight = constants.SIGNATURE_SIZE.M.HEIGHT;
    const signatureWidth = constants.SIGNATURE_SIZE.M.WIDTH;
    let currentRow = 0;
    let itemsInCurrentRow = 0;
    let itemsStacked = 0;
    const newAddedSigneesToCurrentPage: (Signee | SignSignee)[] = [];
    let signeesOnCurrentPage: (Signee | SignSignee)[];

    const signaturesPerRow = Math.floor(
      pageDimensions.width / (signatureWidth + signatureMargin)
    );

    // division by 2 is to calculate only half of the page
    const rowsPerPage = Math.floor(
      pageDimensions.height / 2 / (signatureHeight + signatureMargin)
    );
    const stackingLimit = signaturesPerRow * rowsPerPage;

    const signStore = useSignStore();
    if (isSigningContext) {
      signeesOnCurrentPage =
        signStore.signInfo?.document.signees.filter(
          (signee) => signee.autographPosition?.pageNumber === currentPageNumber
        ) ?? ([] as Signee[]);
    } else {
      const createStore = useCreateStore();
      signeesOnCurrentPage =
        createStore.filteredSignees.filter(
          (signee) => signee.autographPosition?.pageNumber === currentPageNumber
        ) ?? ([] as Signee[]);
    }

    const numberOfSigneesPlacedOnCurrentPage = signeesOnCurrentPage.length;

    if (numberOfSigneesPlacedOnCurrentPage > 0) {
      currentRow = Math.floor(
        numberOfSigneesPlacedOnCurrentPage / signaturesPerRow
      );
      itemsInCurrentRow =
        numberOfSigneesPlacedOnCurrentPage - currentRow * signaturesPerRow;
    }

    const signatureDimensions: SignatureDimensions = {
      width: signatureWidth,
      height: signatureHeight,
      margin: signatureMargin
    };

    const indexesAvailability = this.getIndexesAvailability(
      rowsPerPage,
      stackingLimit,
      pageRatio,
      signatureDimensions,
      pageLimits,
      currentPageNumber,
      signeesOnCurrentPage
    );
    for (const signee of signaturesToPlace) {
      const signaturesPlaced: (Signee | SignSignee)[] =
        signeesOnCurrentPage.concat(newAddedSigneesToCurrentPage);
      let index = indexesAvailability.indexOf(true);
      if (index < 0) {
        index = currentRow * signaturesPerRow + itemsInCurrentRow;
      }
      const signature = document.getElementById(
        `signature-preview-${signee.signeeId}`
      );

      if (!signature) {
        throw new Error(
          `Error placing signature, signature with id signature-preview-${signee.signeeId} not found`
        );
      }

      const signaturePosition: SignaturePosition = {};

      signaturePosition.id = signee.signeeId;
      signaturePosition.page = currentPageNumber;
      signaturePosition.signatureHeight = signatureHeight;
      signaturePosition.signatureWidth = signatureWidth;

      if (index <= stackingLimit - 1) {
        const calculatedPosition = this.getSignaturePositionByIndex(
          index,
          rowsPerPage,
          stackingLimit,
          pageRatio,
          signatureDimensions,
          pageLimits
        );
        signaturePosition.positionY = calculatedPosition.positionY;
        signaturePosition.positionX = calculatedPosition.positionX;
      } else {
        if (itemsStacked === 0) {
          itemsStacked =
            numberOfSigneesPlacedOnCurrentPage +
            newAddedSigneesToCurrentPage.length -
            stackingLimit;
        }
        const stackBasePosition = this.getStackBasePosition(
          rowsPerPage,
          stackingLimit,
          pageRatio,
          signatureDimensions,
          pageLimits
        );
        itemsStacked++;
        signaturePosition.positionX =
          stackBasePosition.positionX + itemsStacked * 5;
        signaturePosition.positionY =
          stackBasePosition.positionY - itemsStacked * 5;
      }

      const updatedSignee = { ...signee };
      updatedSignee.autographPosition = {
        pageNumber: signaturePosition.page,
        x: pixelsToPoints(signaturePosition.positionX),
        y: pixelsToPoints(signaturePosition.positionY),
        width: pixelsToPoints(signaturePosition.signatureWidth),
        height: pixelsToPoints(signaturePosition.signatureHeight)
      };

      for (const signaturePlaced of signaturesPlaced) {
        if (
          signaturePlaced?.autographPosition &&
          overlaps(
            signaturePlaced?.autographPosition,
            updatedSignee.autographPosition
          )
        ) {
          console.log('DEBUG: Collision', signaturePlaced.email);
        }
      }

      await setSignaturePosition(signaturePosition);

      signature.style.position = 'absolute';
      signature.style.display = 'inline';

      // Order is important to check collisions and stack when limit reached
      // eslint-disable-next-line no-await-in-loop
      await this.addSignaturePlaceholderToDocumentPreview(
        signeeId,
        updatedSignee,
        newPageDetails
      );
      itemsInCurrentRow++;

      if (itemsInCurrentRow === signaturesPerRow) {
        // Go to next Row
        currentRow++;
        // Reset items in current row
        itemsInCurrentRow = 0;
      }
      newAddedSigneesToCurrentPage.push(updatedSignee);

      if (index >= 0 && index < stackingLimit) {
        indexesAvailability[index] = false;
      }
    }
  }

  async placeSignatureByScreenPosition(
    setSignaturePosition: (
      signaturePosition: SignaturePosition
    ) => Promise<void>,
    options: PlaceSignatureOptions,
    pageDetails: PDFPageProxy
  ) {
    const pdfPreview = this.getPdfPreviewElement();
    const pages = this.getPageNodes(pdfPreview);
    const currentPage = this.getCurrentPage(pages, options.pageNumber);
    const pageRatio = this.getPageRatio(pageDetails, currentPage);
    const signature = this.getSignatureElement(options.signeeId);
    const pageLimits = this.getPageLimits(options.pageNumber, pages);
    const signaturePosition = this.calculateSignaturePosition(
      signature,
      pageRatio,
      pageLimits,
      currentPage,
      options
    );

    await setSignaturePosition(signaturePosition);
  }

  getSigneesOrderedByEmail(
    signees: (Signee | SignSignee)[],
    userEmail: string
  ) {
    if (!userEmail) {
      return orderBy(signees, ['email'], ['asc']);
    }

    const userSignees: (Signee | SignSignee)[] = [];
    const otherSignees: (Signee | SignSignee)[] = [];

    for (const signee of signees) {
      if (this.compareString(signee.email, userEmail)) {
        userSignees.push(signee);
      } else {
        otherSignees.push(signee);
      }
    }

    return [...userSignees, ...orderBy(otherSignees, ['email'], ['asc'])];
  }

  getOrderedSignees(signees: (Signee | SignSignee)[], signeeId: string) {
    if (!signeeId) {
      return orderBy(signees, ['email'], ['asc']);
    }

    const currentUserSignee = signees.find(
      (signee) => signee.signeeId === signeeId
    );

    // If the current user's signee object is not found, return all signees sorted
    if (!currentUserSignee) {
      return orderBy(signees, ['email'], ['asc']);
    }

    const otherSignees: (Signee | SignSignee)[] = [];
    const userSignatures: (Signee | SignSignee)[] = [];

    // Iterate through all signees to categorize them based on their relationship to the current user
    for (const signee of signees) {
      if (signee.signeeId !== signeeId) {
        // If emails match and signeeId is different, it's another signature of the same user
        if (this.compareString(signee.email, currentUserSignee.email)) {
          userSignatures.push(signee);
        } else {
          // Otherwise, it's a different user
          otherSignees.push(signee);
        }
      }
    }

    return orderBy(
      [currentUserSignee, ...userSignatures, ...otherSignees],
      ['email'],
      ['asc']
    );
  }

  async makeSignatureResizable(
    signeeId: string,
    currentPageNumber: number,
    pageDetails: PDFPageProxy
  ) {
    const element = document.getElementById(`signature-preview-${signeeId}`);
    const pdfPreview = document.getElementById('pdf-preview');
    const resizer = document.getElementById(
      `signature-resizer-${signeeId}`
    ) as ResizableHTMLElement;

    if (!pdfPreview) {
      throw new Error('Error makeSignatureResizable(), pdf preview not found');
    }

    if (!element) {
      throw new Error(
        `Error placeSignatureByScreenPosition(), signature placeholder not found for ${signeeId}`
      );
    }

    if (!resizer) {
      throw new Error(
        `Error placeSignatureByScreenPosition(), resizer not found for ${signeeId}`
      );
    }

    const pdfPreviewElement = document.getElementById('pdf-preview');
    const pageNodes =
      pdfPreviewElement?.getElementsByClassName('pageplaceholder') || [];

    const currentPage = pageNodes[currentPageNumber - 1];
    const maxHeight = constants.SIGNATURE_SIZE.L.HEIGHT;
    const minHeight = constants.SIGNATURE_SIZE.XS.HEIGHT;
    const maxWidth = constants.SIGNATURE_SIZE.L.WIDTH;
    const minWidth = constants.SIGNATURE_SIZE.XS.WIDTH;
    const pageRatio = this.getPageRatio(pageDetails, currentPage);

    let originalWidth = 0;
    let originalMouseX = 0;
    const pageLimits = this.getPageLimits(currentPageNumber, pageNodes);

    element.style.maxWidth = maxWidth * pageRatio.widthRatio + 'px';
    element.style.minWidth = minWidth * pageRatio.widthRatio + 'px';
    element.style.maxHeight = maxHeight * pageRatio.heightRatio + 'px';
    element.style.minHeight = minHeight * pageRatio.heightRatio + 'px';

    // Remove listener to avoid multiple call to the backend
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    resizer.removeEventListener('mousedown', resizer.mouseDownHandler);
    resizer.mouseDownHandler = function onMouseDown(e: MouseEvent) {
      e.preventDefault();
      originalWidth = parseFloat(
        getComputedStyle(element, null)
          .getPropertyValue('width')
          .replace('px', '')
      );
      originalMouseX = e.pageX;
      window.addEventListener('mousemove', resize);
      window.addEventListener('mouseup', stopResize);
    };

    resizer.addEventListener('mousedown', resizer.mouseDownHandler);

    function resize(e: MouseEvent) {
      if (!element) {
        if (!element) {
          throw new Error('Error, resize -> element not found');
        }
      }
      const aspectRatio = 260 / 137;
      const width =
        originalWidth + (e.pageX - originalMouseX) <=
        maxWidth * pageRatio.widthRatio
          ? originalWidth + (e.pageX - originalMouseX)
          : maxWidth * pageRatio.widthRatio;
      const height = width / aspectRatio;
      if (
        element.offsetLeft + width <= pageLimits.right &&
        height + element.offsetTop <= pageLimits.bottom
      ) {
        element.style.width = width + 'px';
        element.style.height = height + 'px';
      }
    }

    function stopResize() {
      window.removeEventListener('mousemove', resize);
      window.removeEventListener('mouseup', stopResize);
      const signStore = useSignStore();
      signStore.signaturePosition = getSignaturePosition(signeeId);
    }

    function getSignaturePosition(signeeId: string) {
      if (!element) {
        throw new Error(
          'Error, resize -> getSignaturePosition element not found'
        );
      }

      const lowerCaseId = signeeId?.toLowerCase();
      const { widthRatio, heightRatio } = pageRatio;
      const offsetX = element.offsetLeft / widthRatio;
      const offsetY =
        (pageLimits.top + currentPage.clientHeight - element.offsetTop) /
        heightRatio;
      const elementHeight = element.clientHeight / heightRatio;
      const elementWidth = element.clientWidth / widthRatio;

      const signaturePosition: SignaturePosition = {
        id: lowerCaseId,
        page: currentPageNumber,
        positionX: offsetX,
        positionY: offsetY - elementHeight,
        signatureHeight: elementHeight,
        signatureWidth: elementWidth
      };

      return signaturePosition;
    }
  }

  async addSignaturePlaceholderToDocumentPreview(
    signeeId: string,
    signee: Signee | SignSignee,
    pageDetails: PDFPageProxy
  ) {
    const pdfPreview = this.getPdfPreviewElement();
    this.validateSigneeAutographPosition(signee);
    const pageNodes = this.getPageNodes(pdfPreview);
    const signature = this.getSignatureElement(signee.signeeId);
    const pageNumber = signee.autographPosition?.pageNumber;

    if (!pageNumber) {
      throw new Error(
        'Autograph position not set for signee ' + signee.signeeId
      );
    }

    if (signee.signStatus !== SignStatus.SIGNED && pageDetails && signature) {
      const currentPage = this.getCurrentPage(pageNodes, pageNumber);
      const pageLimits = this.getPageLimits(pageNumber, pageNodes);
      const screenPosition = this.getSignatureScreenPosition(
        pageLimits,
        currentPage,
        pageDetails,
        signee
      );
      this.applySignatureStyles(signature, screenPosition);
      await this.makeSignatureResizable(
        signee.signeeId,
        pageNumber,
        pageDetails
      );

      if (signeeId === signee.signeeId) {
        signature.style.zIndex = '1';
      }

      pdfPreview.appendChild(signature);
    }
  }

  removeSignaturePlaceholder(signeeId: string) {
    const pdfPreview = this.getPdfPreviewElement();
    const signature = this.getSignatureElement(signeeId);

    if (!signature) {
      throw new Error(
        `Error placing signature, signature with id signature-preview-${signeeId} not found`
      );
    }
    pdfPreview.removeChild(signature);
  }

  getEventPageNumber(event: TouchEvent) {
    const touchX = event.changedTouches[0].clientX;
    const touchY = event.changedTouches[0].clientY;

    const canvases = document.querySelectorAll('canvas');

    for (const canvas of canvases) {
      const rect = canvas.getBoundingClientRect();

      if (
        touchX >= rect.left &&
        touchX <= rect.right &&
        touchY >= rect.top &&
        touchY <= rect.bottom
      ) {
        return parseInt(canvas.parentNode?.id.replace('id-', ''));
      }
    }

    return null;
  }

  adjustVisualPosition(signature: HTMLElement, pageLimits: PageLimits) {
    const rightLimit = pageLimits.right - signature.clientWidth;
    const bottomLimit = pageLimits.bottom - signature.clientHeight;

    // Constrain signature horizontally
    signature.style.left = `${clamp(
      signature.offsetLeft,
      pageLimits.left,
      rightLimit
    )}px`;

    // Constrain signature vertically
    signature.style.top = `${clamp(
      signature.offsetTop,
      pageLimits.top,
      bottomLimit
    )}px`;
  }

  getStackBasePosition(
    rowsPerPage: number,
    stackingLimit: number,
    pageRatio: PageRatio,
    signatureDimensions: SignatureDimensions,
    pageLimits: PageLimits
  ) {
    const { height, width, margin } = signatureDimensions;
    const horizontalMargin =
      margin * pageRatio.widthRatio * (stackingLimit / rowsPerPage);
    const verticalMargin = margin * pageRatio.heightRatio * rowsPerPage;
    const positionX =
      horizontalMargin + width * (stackingLimit / rowsPerPage - 1);
    const positionY =
      (pageLimits.bottom - pageLimits.top) / 2 / pageRatio.heightRatio -
      height -
      height * (rowsPerPage - 1) -
      verticalMargin;
    return {
      positionX,
      positionY
    };
  }

  getSignaturePositionByIndex(
    index: number,
    rowsPerPage: number,
    stackingLimit: number,
    pageRatio: PageRatio,
    signatureDimensions: SignatureDimensions,
    pageLimits: PageLimits
  ) {
    const { width, height, margin } = signatureDimensions;
    const signaturesPerRow = stackingLimit / rowsPerPage;
    const currentRow = Math.floor(index / signaturesPerRow);
    const itemsInCurrentRow = index - currentRow * signaturesPerRow;
    const horizontalMargin =
      margin * pageRatio.widthRatio * (itemsInCurrentRow + 1);
    const verticalMargin = margin * pageRatio.heightRatio * (currentRow + 1);
    const positionX = horizontalMargin + width * itemsInCurrentRow;
    const positionY =
      (pageLimits.bottom - pageLimits.top) / 2 / pageRatio.heightRatio -
      height -
      height * currentRow -
      verticalMargin;
    return {
      positionX,
      positionY
    };
  }

  getSignaturePointsPositionByIndex(
    index: number,
    rowsPerPage: number,
    stackingLimit: number,
    pageRatio: PageRatio,
    signatureDimensions: SignatureDimensions,
    pageLimits: PageLimits,
    currentPageNumber: number
  ) {
    const { width, height } = signatureDimensions;
    const { positionY, positionX } = this.getSignaturePositionByIndex(
      index,
      rowsPerPage,
      stackingLimit,
      pageRatio,
      signatureDimensions,
      pageLimits
    );

    return {
      pageNumber: currentPageNumber,
      x: pixelsToPoints(positionX),
      y: pixelsToPoints(positionY),
      width: pixelsToPoints(width),
      height: pixelsToPoints(height)
    };
  }

  getIndexesAvailability(
    rowsPerPage: number,
    stackingLimit: number,
    pageRatio: PageRatio,
    signatureDimensions: SignatureDimensions,
    pageLimits: PageLimits,
    currentPageNumber: number,
    placedSignees: (Signee | SignSignee)[]
  ) {
    if (placedSignees.length === 0) {
      return Array(stackingLimit).fill(true);
    }

    const indexPositions: AutographPosition[] = [];
    const availability = new Map<number, boolean>();

    // Initialize availability map
    for (let i = 0; i < stackingLimit; i++) {
      availability.set(i, true);
      indexPositions[i] = this.getSignaturePointsPositionByIndex(
        i,
        rowsPerPage,
        stackingLimit,
        pageRatio,
        signatureDimensions,
        pageLimits,
        currentPageNumber
      );
    }

    // Check for overlaps
    placedSignees.forEach((placedSignee) => {
      if (!placedSignee.autographPosition) {
        return;
      }
      for (let i = 0; i < stackingLimit; i++) {
        if (overlaps(indexPositions[i], placedSignee.autographPosition)) {
          availability.set(i, false);
        }
      }
    });

    return Array.from(availability.values());
  }

  compareString(first: string, second: string, insensitive = true): boolean {
    if (!first || !second) {
      return false;
    }
    return insensitive
      ? first.toLowerCase() === second.toLowerCase()
      : first === second;
  }

  getPdfPreviewElement() {
    const pdfPreview = Array.from(document.getElementsByClassName('OneColumn'));
    if (!pdfPreview) {
      throw new Error('PDF preview not found');
    }
    return pdfPreview[0];
  }

  validateSigneeAutographPosition(signee: Signee | SignSignee) {
    if (!signee.autographPosition) {
      throw new Error(
        `Autograph position not specified for signee ${signee.signeeId}`
      );
    }
  }

  getPageNodes(pdfPreview: HTMLElement): HTMLElement[] {
    return Array.from(pdfPreview.children);
  }

  getSignatureElement(signeeId: string) {
    const signatureElement = document.getElementById(
      `signature-preview-${signeeId}`
    );
    if (!signatureElement) {
      throw new Error(`Signature element not found for ${signeeId}`);
    }
    return signatureElement;
  }

  getCurrentPage(pageNodes: HTMLElement[], pageNumber: number) {
    return pageNodes[pageNumber - 1];
  }

  applySignatureStyles(signature: HTMLElement, screenPosition: ScreenPosition) {
    Object.assign(signature.style, {
      position: 'absolute',
      ...screenPosition
    });
  }

  calculateSignaturePosition(
    signature: HTMLElement,
    pageRatio: PageRatio,
    pageLimits: PageLimits,
    currentPage: HTMLElement,
    options: PlaceSignatureOptions
  ): SignaturePosition {
    return {
      id: options.signeeId,
      page: options.pageNumber,
      signatureHeight: signature.clientHeight / pageRatio.heightRatio,
      signatureWidth: signature.clientWidth / pageRatio.widthRatio,
      positionX: signature.offsetLeft / pageRatio.widthRatio,
      positionY:
        (pageLimits.top + currentPage.clientHeight - signature.offsetTop) /
          pageRatio.heightRatio -
        signature.clientHeight / pageRatio.heightRatio
    };
  }
}

export default new SignatureService();
