<template>
  <DeepDialog v-model="showDialog" width="600" persistent :closable="false">
    <template #card-title-content-title>
      <div class="h-[40px] flex items-center">
        {{ titleI18nByErrorCode }}
      </div>
    </template>
    <template #content>
      <span class="text-black text-center text-base">
        {{ msgI18nByErrorCode }}
      </span>
    </template>
    <template #actions>
      <VBtnPrimary @click="refresh">
        {{ i18n.t('dialogs.session_invalid.btn_login') }}
      </VBtnPrimary>
    </template>
  </DeepDialog>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { ref, computed, onBeforeUnmount } from 'vue';
import { useEmitter } from '@/composables/useEmitter.ts';
import { DeepDialog } from '@deepcloud/deep-ui-lib';

const emitter = useEmitter();
emitter.$on('open-session-invalid-dialog', openDialog);

const ERROR_CODES = {
  SESSION_EXPIRED: 'SESSION_EXPIRED',
  LOGGED_OUT: 'LOGGED_OUT'
};

const i18n = useI18n();
const showDialog = ref(false);
const errorCode = ref<string | null>(ERROR_CODES.LOGGED_OUT);

const msgI18nByErrorCode = computed(() => {
  switch (errorCode.value) {
    case ERROR_CODES.LOGGED_OUT:
      return i18n.t('dialogs.session_invalid.loggedout.msg');
    case ERROR_CODES.SESSION_EXPIRED:
      return i18n.t('dialogs.session_invalid.session_expired.msg');
    default:
      return '';
  }
});

const titleI18nByErrorCode = computed(() => {
  switch (errorCode.value) {
    case ERROR_CODES.LOGGED_OUT:
      return i18n.t('dialogs.session_invalid.loggedout.title');
    case ERROR_CODES.SESSION_EXPIRED:
      return i18n.t('dialogs.session_invalid.session_expired.title');
    default:
      return '';
  }
});

onBeforeUnmount(() => {
  clear();
  emitter.$off('open-session-invalid-dialog', openDialog);
});

function openDialog({ message }) {
  errorCode.value = message;
  showDialog.value = true;
}

function clear() {
  errorCode.value = null;
  showDialog.value = false;
}

function refresh() {
  showDialog.value = false;
  return window.location.reload();
}
</script>
