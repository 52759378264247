import { createApp, watchEffect } from 'vue';
import App from './App.vue';
import globalAxios from './composables/useAxios';
import AuthService from '@/services/auth-service';
// Plugins
import { registerPlugins } from '@/plugins';
import PortalVue from 'portal-vue';

import '@/assets/main.css';
import '@/styles/main.scss';
import '@/assets/fontawesome/css/all.css';
import '@deepcloud/deep-ui-lib/dist/style.css';

import {
  locales,
  setI18nLocale as setI18nLocaleDeepUiLib
} from '@deepcloud/deep-ui-lib';
import {
  localeDE,
  localeEN,
  localeFR,
  localeIT,
  setI18nLocale as setI18nLocaleFieldValidators
} from '@deepcloud/field-validators';
import i18n from '@/plugins/i18n.ts';

// Add DeepUiLib locales to app vue-i18n instance
i18n.global.mergeLocaleMessage('de', locales.de);
i18n.global.mergeLocaleMessage('fr', locales.fr);
i18n.global.mergeLocaleMessage('it', locales.it);
i18n.global.mergeLocaleMessage('en', locales.en);

// Add DeepFieldValidators locales to app vue-i18n instance
i18n.global.mergeLocaleMessage('de', localeDE);
i18n.global.mergeLocaleMessage('fr', localeFR);
i18n.global.mergeLocaleMessage('it', localeIT);
i18n.global.mergeLocaleMessage('en', localeEN);

// update plugins i18n locale
watchEffect(() => {
  setI18nLocaleFieldValidators(i18n.global.locale.value);
  setI18nLocaleDeepUiLib(i18n.global.locale.value);
});

AuthService.initKeycloak(onAuthFinish);
async function onAuthFinish() {
  const app = createApp(App);

  globalAxios.defaults.headers.Authorization = AuthService.fullAccessToken;
  app.use(PortalVue);
  registerPlugins(app);
  app.mount('#app');

  // With this "hack" we force the KC token to be always updaten on window focus
  // this is needed for the logout from another domain like: deepsign.swiss

  // UPDATE: we need to update the token as soon as the tab get visible, not only when get focused
  // because the user can still click on a link that would cause an issue
  const isPublicDocument = window.location.href.includes('/p/document');
  if (AuthService.isAuthenticated && !isPublicDocument) {
    window.addEventListener(
      'focus',
      function () {
        AuthService.updateToken(-1);
      },
      false
    );

    document.addEventListener(
      'visibilitychange',
      function () {
        if (document.visibilityState === 'visible') {
          AuthService.updateToken(-1);
        }
      },
      false
    );
  }
}
