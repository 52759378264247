<template>
  <VTextField
    ref="searchBarInput"
    v-model="query"
    data-test-id="search-bar-input"
    :label="i18n.t('actions.search')"
    prepend-inner-icon="fa-regular fa-magnifying-glass"
    :placeholder="i18n.t('actions.search')"
    hide-details
    single-line
    clearable
    variant="solo"
    clear-icon="fa-regular fa-circle-x"
    class="lg:max-w-[49%] search-bar-input"
    tabindex="0"
  ></VTextField>
</template>
<script setup lang="ts">
import { useRouteQuery } from '@vueuse/router';
import { useI18n } from 'vue-i18n';
import { onUnmounted, ref, watch } from 'vue';
import { useEmitter } from '@/composables/useEmitter';
import { nextTick } from 'vue';

const i18n = useI18n();
const searchBarInput = ref();
const query = useRouteQuery('q', undefined);
const emitter = useEmitter();

async function focusInput() {
  await nextTick(() => {
    searchBarInput.value?.focus();
  });
}

emitter.$on('focus-search-bar', focusInput);

onUnmounted(() => {
  emitter.$off('focus-search-bar', focusInput);
}),
  // Remove empty values from query
  watch(
    query,
    () => {
      if (query.value === '') {
        query.value = undefined;
      }
    },
    {
      immediate: true
    }
  );
</script>

<style lang="scss" scoped>
.search-bar-input :deep(.v-field) {
  @apply border border-gray-300 rounded-lg focus-within:border focus-within:border-primary;
}
</style>
