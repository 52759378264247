<template>
  <v-card
    class="get-deep-id-card text-center"
    flat
    rounded="lg"
    style="width: 100%"
  >
    <div class="mb-2">
      <figure v-if="userValidationURL" class="qrcode">
        <VueQrcode :options="qrOptions" :value="userValidationURL" />
        <img alt="deep-id" class="qrcode-image" src="/img/qr-logo.png" />
      </figure>
      <v-progress-circular
        v-else
        color="primary"
        indeterminate
        size="60"
        style="margin: 70px"
      />
    </div>

    <a
      class="identification-link text-primary"
      target="_blank"
      :href="userValidationURL"
    >
      {{ userValidationURL }}
    </a>
    <div
      v-if="userValidationURL"
      class="d-flex flex-row flex-fill justify-center pt-5"
    >
      <div class="d-flex flex-row scan-container align-center">
        <v-icon class="mr-3" color="#090909" size="24"> fal fa-mobile </v-icon>
        <div class="deep-caption text-left scan-hint">
          {{ i18n.t('hints.scan_qr_code') }}
        </div>
      </div>
    </div>

    <div class="mt-4">
      <v-tooltip
        location="top"
        content-class="tooltip tooltip-top deep-light"
        :aria-label="i18n.t('actions.new_qrcode')"
        open-on-click
      >
        <template #activator="{ props: ActivatorProps }">
          <a
            v-bind="ActivatorProps"
            class="re-identification-link pointer-events-auto text-primary cursor-pointer"
            @click="restartVerification"
          >
            {{ i18n.t('actions.new_qrcode') }}
          </a>
        </template>
        <div class="tooltip-container">
          <span>
            {{ i18n.t('hints.restart_verification_hint') }}
          </span>
        </div>
      </v-tooltip>
    </div>
  </v-card>
</template>

<script setup lang="ts">
import VueQrcode from '@chenfengyuan/vue-qrcode';
import { computed, type PropType } from 'vue';
import { useEmitter } from '@/composables/useEmitter';
import { useI18n } from 'vue-i18n';
import type { UserValidation } from '@/types/UserValidation';

const props = defineProps({
  userValidations: {
    type: Array as PropType<UserValidation[]>,
    default: undefined
  }
});
const emitter = useEmitter();

const i18n = useI18n();

function restartVerification() {
  emitter.$emit('restart-verification');
}

const qrOptions = {
  errorCorrectionLevel: 'Q',
  width: 200
};

const userValidationURL = computed<string>(() => {
  return props.userValidations && props.userValidations.length
    ? props.userValidations.slice(-1)[0].URL
    : '';
});
</script>

<style scoped lang="scss">
@import '@/styles/core/colors';

.get-deep-id-card {
  background-color: $grey-lighten-4;
  padding: 24px !important;
}

.qrcode {
  display: inline-block;
  font-size: 0;
  margin-bottom: 0;
  position: relative;

  canvas {
    border-radius: 12px !important;
  }
}

.qrcode-image {
  background-color: #fff;
  border: 0.25rem solid #fff;
  border-radius: 0.25rem;
  height: 20%;
  left: 50%;
  overflow: hidden;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 20%;
}

.identification-link {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}

.re-identification-link {
  text-decoration: none;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}

.scan-container {
  max-width: 240px;
}

.scan-hint {
  font-size: 12px !important;
  line-height: 16px !important;
}

.tooltip {
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2) !important;
}

.tooltip-container {
  max-width: 200px;
  text-align: center;
}
</style>
