<template>
  <v-app>
    <Snackbar />
    <Layout>
      <RouterView></RouterView>
    </Layout>
    <FeedbackDialog />
    <SupportDialog />
    <DialogSessionInvalid />
  </v-app>
</template>

<script setup lang="ts">
import Layout from '@/layouts/Layout.vue';
import { usePreferencesStore } from '@/stores/deepadmin/preferences.ts';
import AuthService from '@/services/auth-service.ts';
import { getUserById } from '@/api/deepadmin/user.ts';
import { PreferenceOptions } from '@/types/enums/PreferenceOptions.ts';
import { AuthorityService } from '@/types/enums/AuthorityService.ts';
import { useDocumentAuth } from '@/composables/useDocumentAuth.ts';
import { computed } from 'vue';
import { globalRouterReference } from '@/router/globalRouterReference';
import { useRouter } from 'vue-router';
const preferencesStore = usePreferencesStore();
const documentAuth = useDocumentAuth();
globalRouterReference.router = useRouter();

const isDocumentAuthAuthenticated = computed(() => {
  return documentAuth.isAuthenticated.value;
});

if (AuthService.isAuthenticated && !isDocumentAuthAuthenticated.value) {
  preferencesStore.fetchAll().then((response) => {
    const preferences = response.data.preferences;
    // check if user as a default AuthorityService set
    if (!preferences.defaultAuthorityService) {
      // otherwise check if user as a valid Mobile ID, if true MobileID becomes default value
      getUserById(AuthService.user.sub, true).then((response) => {
        if (response.data && response.data.is_mobile_id) {
          preferencesStore.setPreference(
            PreferenceOptions.DEFAULT_AUTHORITY_SERVICE,
            AuthorityService.MOBILE_ID
          );
        }
      });
    }
  });
}
</script>
