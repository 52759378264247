<template>
  <v-menu v-model="menu" location="bottom right">
    <template #activator="{ props: ActivatorProps }">
      <v-btn
        v-show="availableActions.length > 0 || menu"
        v-bind="ActivatorProps"
        size="x-small"
        class="signee-menu-activator"
        aria-label="Participant menu"
      >
        <v-icon class="text-gray-500"> fa-regular fa-ellipsis-vertical </v-icon>
      </v-btn>
    </template>
    <v-card class="menu-card" min-width="195">
      <v-list class="pa-0 ma-0" density="compact">
        <template
          v-for="(item, index) in items"
          :key="`document-menu-item-${index}`"
        >
          <v-list-item
            v-if="item.visible"
            class="pl-2 pr-0 h-[16px]"
            link
            @click="item.action"
          >
            <v-list-item-title
              class="document-menu-item-title d-flex flex-row align-center justify-start"
              :data-test-id="`${item.title}`"
              d-flex
              flex-row
            >
              {{ item.title }}
            </v-list-item-title>
          </v-list-item>
        </template>
      </v-list>
    </v-card>
  </v-menu>
</template>
<script setup lang="ts">
import { computed, type PropType, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import type { SignObserverPolicy } from '@/types/deepsign/SignObserverPolicy';
import type { SignSigneePolicy } from '@/types/deepsign/SignSigneePolicy';
import { useSignContext } from '@/composables/useSignContext';
import { SignStatus } from '@/types/enums/SignStatus';
import { useGlobalStore } from '@/stores/global/global';
import type { DocumentDialogState } from '@/types/ui';
import { useDocumentContext } from '@/composables/useDocumentContext';
import authService from '@/services/auth-service';

const props = defineProps({
  participantId: {
    type: String,
    default: undefined
  },
  participantEmail: {
    type: String,
    default: undefined
  },
  participantPolicy: {
    type: Object as PropType<SignSigneePolicy | SignObserverPolicy>,
    default: undefined
  },
  participantStatus: {
    type: String as PropType<SignStatus>,
    default: undefined
  },
  participantType: {
    type: String as PropType<'observer' | 'signee' | 'approve'>,
    default: 'signee'
  },
  showActivator: {
    type: Boolean,
    default: false
  }
});

const i18n = useI18n();
const globalStore = useGlobalStore();
const { policy, signeeId } = useSignContext();

const menu = ref(false);

const { currentDocument } = useDocumentContext();

const canForward = computed(() => {
  return (
    authService.isAuthenticated &&
    policy.value.canForward &&
    props.participantId === signeeId.value &&
    (isSignee.value || isApprover.value)
  );
});

const items = computed(() => {
  return [
    {
      title: i18n.t('buttons.edit_email'),
      visible:
        props.participantPolicy?.canUpdate &&
        (isSignee.value || isApprover.value) &&
        !canForward.value,
      action: () => {
        updateStore('edit');
      }
    },
    {
      title: i18n.t('buttons.remove_approver'),
      visible:
        props.participantPolicy?.canDelete &&
        isApprover.value &&
        !canForward.value &&
        (currentDocument.value.signees?.length || 0) > 1,
      action: () => {
        updateStore('remove-approver');
      }
    },
    {
      title: i18n.t('buttons.remove_signee'),
      visible:
        props.participantPolicy?.canDelete &&
        isSignee.value &&
        !canForward.value &&
        (currentDocument.value.signees?.length || 0) > 1,
      action: () => {
        updateStore('remove-signee');
      }
    },
    {
      title: i18n.t('buttons.forward_signature'),
      visible: canForward.value,
      action: () => {
        updateStore('forward');
      }
    },
    {
      title: i18n.t('buttons.resend_invitation'),
      visible:
        props.participantPolicy?.canResendInvitation && !canForward.value,
      action: () => {
        updateStore('resend-invitation');
      }
    },
    {
      title: i18n.t('buttons.remove_observer'),
      visible: props.participantPolicy?.canDelete && isObserver.value,
      action: () => {
        updateStore('remove-observer');
      }
    }
  ];
});

const availableActions = computed(() => {
  return items.value.filter((item) => item.visible);
});

const isSignee = computed(() => {
  return props.participantType === 'signee';
});

const isApprover = computed(() => {
  return props.participantType === 'approve';
});

const isObserver = computed(() => {
  return props.participantType === 'observer';
});

function updateStore(state: DocumentDialogState) {
  globalStore.documentDialogState = state;
  if (
    props.participantType === 'signee' ||
    props.participantType === 'approve'
  ) {
    globalStore.selectedSignee = {
      email: props.participantEmail,
      signeeId: props.participantId
    };
  }

  if (props.participantType === 'observer') {
    globalStore.setSelectedObserver({
      email: props.participantEmail,
      observerId: props.participantId
    });
  }

  globalStore.documentDialog = true;
}
</script>

<style scoped lang="scss">
@import '@/styles/core/colors';

.menu-card {
  padding: 12px;
  border-radius: 12px;
  border: solid 1px #dcdce2;
}

.v-menu__content {
  border-radius: 12px;
}

.theme--light.v-list-item:hover::before {
  opacity: 0;
}

.document-menu-item-title {
  cursor: pointer;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  min-height: 32px;
  justify-content: center;
  align-content: center;

  &:hover {
    color: $primary-color;
  }
}
</style>
