<template>
  <button
    class="flex items-center gap-4"
    data-test-id="back-to-deepbox"
    @click="goBack"
  >
    <i class="fas fa-chevron-left text-xl pl-4" />
    <div class="hidden md:flex md:items-center font-medium">
      <span>
        {{ originApp }}
      </span>
    </div>
  </button>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';

const route = useRoute();
const router = useRouter();
const i18n = useI18n();

// https://dev.deepsign.swiss/deepbox/f6fb8afa-1ef4-411d-a562-3e51592811c4/pending?backRoute=%2F2598a7e2-dd70-4d26-9096-f51c79767886%2Ftypes%2Fdf596b58-b333-4ab2-a2d9-3061b36004a0%2Fboxes%2F4e44a929-ea98-4d54-852d-e831eef21a93%2Ffiles
// localhost:2121/deepbox/f6fb8afa-1ef4-411d-a562-3e51592811c4/pending?backRoute=%2F2598a7e2-dd70-4d26-9096-f51c79767886%2Ftypes%2Fdf596b58-b333-4ab2-a2d9-3061b36004a0%2Fboxes%2F4e44a929-ea98-4d54-852d-e831eef21a93%2Ffiles

const backUrl = computed(() => route.query?.backUrl);

const urlType = computed(() => {
  if (!backUrl.value) return undefined;

  try {
    // Check for portal url, return 'portal'
    const regexPortal = /^portal\.((dev(\d+)?|int|demo)\.|)deepbox\.swiss$/;
    const url = new URL(decodeURIComponent(String(backUrl.value)));
    // Check for deepbox url, return 'deepbox'
    const regexDeepBox = /^((dev(\d+)?|app|int|demo)\.|)deepbox\.swiss$/;
    if (regexPortal.test(String(url.hostname))) return 'portal';
    if (regexDeepBox.test(String(url.hostname))) return 'deepbox';
    if (String(url.hostname).startsWith('localhost')) return 'localhost';
  } catch (e) {
    console.error('Invalid URL:', backUrl.value, e);
  }
  return undefined;
});

const originApp = computed(() => {
  switch (urlType.value) {
    case 'portal':
      return i18n.t('buttons.back_to_deepportal');
    case 'deepbox':
      return i18n.t('buttons.back_to_deepbox');
    case 'localhost':
      return i18n.t('buttons.back');
    default:
      return i18n.t('buttons.overview');
  }
});

function goBack() {
  if (backUrl.value && urlType.value) {
    console.log('backUrl', backUrl.value);
    console.log('urlType', urlType.value);
    window.open(String(backUrl.value), '_self');
  } else {
    const filterSignStatus = sessionStorage.getItem('filterSignStatusSession');
    const filterDocumentStatus = sessionStorage.getItem(
      'documentSignStatusSession'
    );

    router.push({
      name: 'dashboard',
      query: {
        ...route.query,
        ...(filterDocumentStatus
          ? filterDocumentStatus.split(',').reduce(
              (acc, status) => {
                // eslint-disable-next-line no-param-reassign
                acc.filterDocumentStatus = acc.filterDocumentStatus
                  ? [...acc.filterDocumentStatus, status]
                  : [status];
                return acc;
              },
              {} as { filterDocumentStatus?: string[] }
            )
          : {}),
        ...(filterSignStatus
          ? filterSignStatus.split(',').reduce(
              (acc, status) => {
                // eslint-disable-next-line no-param-reassign
                acc.filterSignStatus = acc.filterSignStatus
                  ? [...acc.filterSignStatus, status]
                  : [status];
                return acc;
              },
              {} as { filterSignStatus?: string[] }
            )
          : {})
      }
    });
  }
}
</script>
