import AuthService from '@/services/auth-service';
import globalAxios from '@/composables/useAxios';
import publicAxios from '@/plugins/publicAxios';
import deepSignApi from '@/api/deepsign/deepsignApi';
import i18n from '@/plugins/i18n';
import { useGlobalStore } from '@/stores/global/global';
import { getIsoCodeByLanguage } from '@/composables/useUtils';
import { ref } from 'vue';
import { useLocale } from 'vuetify';
import { useI18n } from 'vue-i18n';
import { useDeepAdminStore } from '@/stores/deepadmin/deepadmin';
import { useStorage } from '@vueuse/core';

export function getAppLocaleFormatted(
  locale: string,
  availableLocales: string[]
) {
  // 1. locale is only 2 chars (de, en, fr, it)
  const localeLowerCaseIdx = availableLocales.findIndex((a) =>
    a.startsWith(locale)
  );
  if (localeLowerCaseIdx !== -1) {
    return availableLocales[localeLowerCaseIdx];
  }
  // 2. locale has wrong format (de-ch, en-uk (or en-us), fr-ch, it-ch)
  // or locale has wrong format (deCH, enUK (or enUS), frCH, itCH)
  const localeWrongFormatWithoutHyphenMinusIdx = locale.indexOf('-');
  if (localeWrongFormatWithoutHyphenMinusIdx !== -1) {
    const localeLowerCaseIdx = availableLocales.findIndex(
      (a) =>
        a.toLowerCase().substring(0, 2) === locale.toLowerCase().substring(0, 2)
    );
    if (localeLowerCaseIdx !== -1) {
      return availableLocales[localeLowerCaseIdx];
    }
  } else {
    const localeLowerCaseIdx = availableLocales.findIndex(
      (a) =>
        a.replace('-', '').substring(0, 2) ===
        locale.toLowerCase().substring(0, 2)
    );
    if (localeLowerCaseIdx !== -1) {
      return availableLocales[localeLowerCaseIdx];
    }
  }

  // if not match, returns the app default locale
  return i18n.global.fallbackLocale.value;
}

export function useLanguage() {
  const globalStore = useGlobalStore();
  const locale = ref<string>(globalStore.locale || 'de');
  const storedAppLocale = useStorage('storedAppLocale', false, localStorage);
  const { current: localeVuetify } = useLocale();
  const { locale: localeI18n, availableLocales } = useI18n();

  const deepAdminStore = useDeepAdminStore();

  const setPluginsLocale = (locale: string) => {
    const localeFormatted = getAppLocaleFormatted(locale, availableLocales);
    localeVuetify.value = localeFormatted.split('-')[0];
    localeI18n.value = localeFormatted;
  };

  async function setLanguage(newLocale: string) {
    locale.value = newLocale;
    globalStore.setLocale(newLocale);
    i18n.global.locale.value = newLocale;
    storedAppLocale.value = newLocale;
    const isoCode = getIsoCodeByLanguage(newLocale);
    globalAxios.defaults.headers.common['Accept-Language'] = isoCode;
    publicAxios.defaults.headers.common['Accept-Language'] = isoCode;
    deepSignApi.defaults.headers.common['Accept-Language'] = isoCode;
    if (AuthService.isAuthenticated) {
      const localeIsoCode = getIsoCodeByLanguage(newLocale);
      await deepAdminStore.storeUserLanguage(localeIsoCode);
    }
  }

  async function initializeLanguage() {
    let defaultLocale = navigator.language;
    if (AuthService.isAuthenticated) {
      defaultLocale = globalStore.locale || i18n.global.fallbackLocale.value;
    } else if (
      !defaultLocale.includes('de') &&
      !defaultLocale.includes('en') &&
      !defaultLocale.includes('fr') &&
      !defaultLocale.includes('it')
    ) {
      defaultLocale = 'de';
    }

    setPluginsLocale(defaultLocale);
  }

  return { setLanguage, initializeLanguage };
}
