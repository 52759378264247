<template>
  <DeepDialog
    v-model="showDialog"
    width="600"
    :title="i18n.t('titles.upload_document')"
    @click:outside="closeDialog"
    @keydown.esc="closeDialog"
    @close="closeDialog"
  >
    <template #content>
      <DeepSignAlert v-if="showAlert" error class="text-red-500 mb-6 w-full">
        <div class="flex flex-row align-center">
          <VIcon :size="32" class="mr-4">
            fa-regular fa-circle-exclamation
          </VIcon>
          <span>{{ showAlertMessage }}</span>
        </div>
      </DeepSignAlert>

      <!-- USER HAS VALID ORGANIZATION -->
      <div
        v-if="filteredOrganizations.length > 0"
        class="flex flex-col items-center"
      >
        <!-- File upload rectangle -->
        <div
          class="flex items-center justify-center upload-zone w-full"
          :class="{
            dragover: hover,
            'justify-center': uploadedFiles.length === 1,
            multiple: uploadedFiles.length > 1,
            disabled: uploadStarted && uploadedFiles.length > 1
          }"
          @click="onCardClick"
        >
          <div class="w-full overflow-y-auto h-[170px] max-h-[170px] grid">
            <input
              ref="fileInput"
              :accept="acceptedExtensions"
              multiple
              class="fileInput"
              type="file"
              :disabled="uploadStarted"
              data-test-id="file-input"
              @input="upload"
            />
            <div
              v-if="uploadedFiles.length === 0"
              class="grid gap-2 items-center justify-center h-auto my-auto"
            >
              <VIcon :size="32" class="text-primary/85 mx-auto">
                fa-regular fa-file-plus
              </VIcon>
              <div class="grid gap-2 text-center">
                <div class="text-sm">
                  {{ i18n.t('texts.drag_drop_or') }}
                  <a
                    tabindex="0"
                    class="text-primary md:hover:underline"
                    @keydown.enter="onCardClick"
                    @keydown.space="onCardClick"
                    >{{ i18n.t('texts.upload_file') }}</a
                  >
                </div>
                <div class="text-xs text-gray-500 px-2">
                  {{ i18n.t('texts.document_size_limit') }}
                </div>
              </div>
            </div>

            <div v-else-if="uploadedFiles.length === 1" class="h-full">
              <div
                class="flex flex-col items-center justify-center h-full deep-callout focus-visible:ring-1 focus-visible:ring-offset-1 focus:ring-primary"
                tabindex="0"
                @keydown.enter="onCardClick"
                @keydown.space="onCardClick"
              >
                <div
                  v-if="uploadedFiles[0].file"
                  class="text-wrap px-2 text-center"
                  style="max-width: 375px"
                  data-test-id="uploaded-file"
                >
                  {{ uploadedFiles[0].file.name }}
                </div>

                <div class="d-flex flex-row justify-center hint-message">
                  <div v-if="uploadedFiles[0].file">
                    {{ humanFileSize(uploadedFiles[0].file.size, 0) }}
                  </div>

                  <v-divider class="mx-3 upload-divider" vertical />
                  <button
                    v-if="uploadedFiles[0]"
                    class="remove-button"
                    @click.stop="
                      uploadedFiles[0].file
                        ? removeFile(uploadedFiles[0])
                        : undefined
                    "
                  >
                    {{ i18n.t('buttons.remove') }}
                  </button>
                </div>
              </div>
            </div>

            <div v-else class="overflow-y-auto">
              <UploadedFile
                v-for="file in uploadedFiles"
                :key="file.key"
                class="w-full text-left pl-2"
                :file="file"
                @remove-uploaded-file="removeFile"
              />

              <button
                v-if="!uploadedFilesHaveError"
                class="pl-2 pb-2 pt-0.5 h-10 md:hidden"
                :class="
                  uploadedFilesHaveError
                    ? 'text-gray-500'
                    : 'text-primary md:hover:underline'
                "
              >
                {{ i18n.t('buttons.upload_more_files') }}
              </button>
            </div>
          </div>
        </div>

        <div
          v-if="successfullyUploadedFilesCount >= 1 || errorsCount.length > 0"
          class="mt-6 grid gap-2 w-full"
        >
          <DeepSignAlert
            v-if="
              successfullyUploadedFilesCount >= 1 && uploadedFiles.length > 1
            "
            class="w-full"
            success
          >
            {{
              isUploadingSeals
                ? i18n.t('aggregated.seals_uploaded', {
                    count: successfullyUploadedFilesCount,
                    total: uploadedFiles.length
                  })
                : i18n.t('aggregated.uploaded', {
                    count: successfullyUploadedFilesCount,
                    total: uploadedFiles.length
                  })
            }}
          </DeepSignAlert>

          <DeepSignAlert
            v-if="uploadedFiles.length > 1 && errorsCount.length > 0"
            warning
            class="w-full"
          >
            <div
              v-for="errorCount in errorsCount"
              :key="errorCount.apiError"
              class="text-left text-xs"
            >
              {{
                i18n.t(`errors.aggregated.${errorCount.apiError}`, {
                  count: errorCount.count,
                  total: uploadedFiles.length
                })
              }}
            </div>
          </DeepSignAlert>
        </div>

        <!-- Organization selector -->
        <div
          v-if="uploadedFiles.length > 0"
          v-show="
            filteredOrganizationsWithoutHeaders.length > 1 &&
            !uploadStarted &&
            allFilesAreReadyToBeUploaded
          "
          class="mt-6 w-full"
        >
          <v-autocomplete
            v-model="selectedOrganization"
            variant="outlined"
            :hide-details="true"
            item-title="display_name"
            :items="filteredOrganizations"
            return-object
            :label="i18n.t('texts.select_organization')"
            data-test-id="organization-select"
            @keydown.backspace="() => (selectedOrganization = undefined)"
            @update:model-value="onOrgSelect"
          >
            <template #selection="{ item }">
              <organization-item :item="item.raw" />
            </template>
            <template #item="{ item, props }">
              <v-list-item-subtitle
                v-if="item.raw.type === 'subheader'"
                class="m-3"
              >
                {{ item.raw.title }}
              </v-list-item-subtitle>
              <v-list-item v-else v-bind="props" title="">
                <organization-item
                  class="w-full flex"
                  :active="item.raw.group_id === selectedOrganization"
                  :item="item.raw"
                  data-test-id="organization-select-item"
                />
              </v-list-item>
            </template>
          </v-autocomplete>
        </div>

        <!-- Only shown when the seal feature is available and the use uploaded only 1 file -->
        <div
          v-if="canSeal && isSealsLoading"
          class="flex justify-center w-full mt-6"
        >
          <v-btn :loading="isSealsLoading"></v-btn>
        </div>

        <!-- Seal or Sign -->
        <div
          v-if="
            !isSealsLoading &&
            !uploadStarted &&
            !addDocumentsFromFilesPending &&
            seals.length > 0 &&
            uploadedFiles.length > 0 &&
            !uploadedFilesHaveError
          "
          class="w-full"
        >
          <div class="flex justify-center flex-column w-full mt-6">
            <v-btn-toggle
              v-model="document_action"
              divided
              mandatory
              variant="outlined"
              color="primary"
              class="relative"
            >
              <VBtn
                class="document-action"
                :disabled="createDisabled || isLoading"
                :loading="addDocumentsFromFilesPending"
                prepend-icon="fa-light fa-pen-nib"
                data-test-id="document-sign"
                :block="false"
              >
                {{ i18n.t('buttons.to_document_sign') }}
              </VBtn>
              <VBtn
                class="document-action"
                :disabled="createDisabled || isLoading"
                :loading="addDocumentsFromFilesPending"
                :organization-id="organizationId"
                data-test-id="document-seal"
                :block="false"
                prepend-icon="fa-regular fa-stamp"
              >
                {{ i18n.t('buttons.to_document_seal') }}
              </VBtn>
            </v-btn-toggle>
          </div>
        </div>
      </div>
      <!-- /USER HAS VALID ORGANIZATION -->

      <!-- NO VALID ORGANIZATION -->
      <div v-else>
        <div class="grid w-full text-left">
          {{ i18n.t('cards.no_valid_company') }}
          <br />
          <br />
          <span v-html="getTranslatedUpgradeAccount()"></span>

          <div v-show="organizations.length > 1" class="mt-6">
            <OrganizationSelector
              v-model="selectedOrganization"
              class="mt-2 px-4"
            />
          </div>
        </div>
        <div v-show="hover" class="drop-overlay"></div>
      </div>
      <!-- NO VALID ORGANIZATION -->
    </template>
    <template #actions>
      <VBtnSecondary
        variant="outlined"
        :text="
          allFilesSuccessfullyUploaded || uploadedFilesHaveError
            ? i18n.t('buttons.close')
            : i18n.t('buttons.cancel')
        "
        data-test-id="dialog-cancel-button"
        @click="closeDialog"
      />
      <VBtnPrimary
        :disabled="createDisabled || isLoading || uploadedFilesHaveError"
        :loading="addDocumentsFromFilesPending"
        :text="createButtonText"
        data-test-id="dialog-continue-button"
        @click="createDocuments"
      />
    </template>
  </DeepDialog>
</template>

<script setup lang="ts">
import { constants } from '@/constants/constants';
import { VerificationState } from '@/types/enums/VerificationState';
import { useDeepAdminStore } from '@/stores/deepadmin/deepadmin';
import { useDocumentStore } from '@/stores/document/document';
import { computed, onUnmounted, ref, watch, watchEffect } from 'vue';
import { useEmitter } from '@/composables/useEmitter';
import { useRoute, useRouter } from 'vue-router';
import { getAllFileEntries, humanFileSize } from '@/composables/useUtils';
import { useI18n } from 'vue-i18n';
import { UploadStatus } from '@/types/enums/UploadStatus';
import { v4 as uuid } from 'uuid';
import { useMessageStore } from '@/stores/message/message';
import type { Company, OrganizationHeader } from '@/types/deepadmin/Company';
import { getCompanySeals } from '@/api/deepsign/users-me-seal';
import { getCompanySettings } from '@/api/deepsign/company-settings';
import type { FileType } from '@/types/FileType';
import { useTerms } from '@/composables/useTerms';
import type { CompanySettings } from '@/types/deepsign/CompanySettings';
import { DeepDialog } from '@deepcloud/deep-ui-lib';

const emit = defineEmits(['done']);

const deepAdminStore = useDeepAdminStore();
const documentStore = useDocumentStore();
const messageStore = useMessageStore();

const { getTranslatedUpgradeAccount } = useTerms();
const emitter = useEmitter();
const i18n = useI18n();

const showDialog = ref(false);
const acceptedExtensions = constants.SUPPORTED_DOCUMENT_TYPES.join(', ');
const hover = ref(false);
const uploadedFiles = ref<FileType[]>([]);
const selectedOrganization = ref<Company>();
const maxFileSizeMB = ref(40);
const uploadStarted = ref(false);
const addDocumentsFromFilesPending = ref(false);
const router = useRouter();
const route = useRoute();
const fileInput = ref();
const seals = ref([]);
const isSealsLoading = ref(false);
const document_action = ref<0 | 1>(0);
const isUploadingSeals = ref(false);
const companySettings = ref<CompanySettings>();

const showAlert = computed(() => {
  return uploadedFiles.value.some((file) => {
    return (
      file.isTooLarge ||
      file.status === UploadStatus.UNSUPPORTED ||
      (file.status === UploadStatus.ERROR && uploadedFiles.value.length === 1)
    );
  });
});

const showAlertMessage = computed(() => {
  if (uploadedFiles.value.length > 1) {
    return i18n.t('texts.unsupported_file_format_or_too_large');
  } else if (
    uploadedFiles.value[0].isTooLarge ||
    uploadedFiles.value[0].status === UploadStatus.UNSUPPORTED
  ) {
    return i18n.t('texts.file_too_large_short');
  } else if (uploadedFiles.value[0].apiError) {
    return i18n.t(`errors.api.${uploadedFiles.value[0].apiError}`);
  } else if (uploadedFiles.value[0].apiErrorMessage) {
    return uploadedFiles.value[0].apiErrorMessage;
  } else {
    return i18n.t('text.generic_upload_error');
  }
});

const createDisabled = computed(() => {
  return (
    filteredOrganizations.value.length > 0 &&
    (uploadedFiles.value.length === 0 ||
      organizationId.value === null ||
      errorsCount.value.length > 0 ||
      showAlert.value)
  );
});

const filteredOrganizations = computed<(Company | OrganizationHeader)[]>(() => {
  if (deepAdminStore.userInfo?.companies) {
    const orgs = deepAdminStore.userInfo?.companies.filter((organization) => {
      return (
        organization.verification_state !== VerificationState.NONE &&
        organization.structure !== 'personal'
      );
    });

    orgs.sort((a, b) => a.display_name.localeCompare(b.display_name));

    const personalSpace = deepAdminStore.userInfo?.companies.find(
      (organization) => {
        return (
          organization.verification_state !== VerificationState.NONE &&
          organization.structure === 'personal'
        );
      }
    );

    let availableOrgs: (Company | OrganizationHeader)[] = [];

    if (orgs.length > 0) {
      availableOrgs.push({
        title: String(i18n.t('labels.organizations')),
        type: 'subheader'
      });
      availableOrgs = [...availableOrgs, ...orgs];
    }

    if (personalSpace) {
      availableOrgs = [
        ...availableOrgs,
        {
          title: String(i18n.t('labels.personal')),
          type: 'subheader'
        },
        { ...personalSpace }
      ];
    }
    return availableOrgs;
  } else {
    return [];
  }
});

const createButtonText = computed(() => {
  if (filteredOrganizations.value.length === 0) {
    return i18n.t('buttons.continue');
  }
  if (uploadedFiles.value.length === 1) {
    if (document_action.value === 0) {
      return i18n.t('buttons.sign');
    }
    if (document_action.value === 1) {
      return i18n.t('buttons.seal');
    }
  }
  if (uploadedFiles.value.length > 1) {
    return i18n.t('buttons.upload');
  }
  return i18n.t('buttons.sign'); // Default
});

const filteredOrganizationsWithoutHeaders = computed(() => {
  return filteredOrganizations.value.filter((org) => !org.type);
});

const organizations = computed(() => {
  if (deepAdminStore.userInfo?.companies) {
    return deepAdminStore.userInfo?.companies.filter((organization) => {
      return organization.structure !== 'personal';
    });
  } else {
    return [];
  }
});

const defaultOrganization = computed(() => {
  const organizations = filteredOrganizationsWithoutHeaders.value as Company[];
  if (organizations.length > 1) {
    return selectedOrganization.value;
  } else if (organizations.length === 1) {
    return organizations[0];
  } else {
    return undefined;
  }
});

const canSeal = computed(() => {
  if (!defaultOrganization.value) return false;
  return (
    defaultOrganization.value?.metadata.beta_features?.includes(
      'deepsign_seals'
    ) ||
    selectedOrganization.value?.metadata.beta_features?.includes(
      'deepsign_seals'
    )
  );
});

const organizationId = computed(() => {
  const organizations = filteredOrganizationsWithoutHeaders.value as Company[];
  if (organizations.length > 1) {
    return selectedOrganization.value?.group_id || null;
  } else if (organizations.length === 1) {
    return organizations[0]?.group_id;
  } else {
    return null;
  }
});

const allFilesSuccessfullyUploaded = computed(() => {
  return uploadedFiles.value.every(
    (file) => file.status === UploadStatus.UPLOADED
  );
});

const uploadedFilesHaveError = computed(() => {
  return uploadedFiles.value.some(
    (file) =>
      file.status === UploadStatus.EXCLUDED ||
      file.status === UploadStatus.ERROR
  );
});

const allFilesAreReadyToBeUploaded = computed(() => {
  return uploadedFiles.value.every(
    (file) => file.status === UploadStatus.READY
  );
});

const successfullyUploadedFilesCount = computed(() => {
  return uploadedFiles.value.filter(
    (file) => file.status === UploadStatus.UPLOADED
  ).length;
});

const errorsCount = computed(() => {
  const apiErrorCounts = uploadedFiles.value.reduce((map, { apiError }) => {
    if (apiError !== undefined) {
      map.set(apiError, (map.get(apiError) || 0) + 1);
    }
    return map;
  }, new Map());

  return Array.from(apiErrorCounts, ([apiError, count]) => ({
    apiError,
    count
  }));
});

const isLoading = computed(() => {
  return uploadedFiles.value.some(
    (file) => file.status === UploadStatus.PROGRESS
  );
});

emitter.$on('open-new-document-dialog', openDialog);
emitter.$on('close-new-document-dialog', closeDialog);
deepAdminStore.fetchUserInfo();

onUnmounted(() => {
  emitter.$off('open-new-document-dialog', openDialog);
});

function openDialog() {
  showDialog.value = true;
  uploadStarted.value = false;
  window.addEventListener('dragenter', dragenter);
  window.addEventListener('dragover', dragover);
  window.addEventListener('dragleave', dragleave);
  window.addEventListener('drop', drop);
}

function closeDialog() {
  window.removeEventListener('dragenter', dragenter);
  window.removeEventListener('dragover', dragover);
  window.removeEventListener('dragleave', dragleave);
  window.removeEventListener('drop', drop);
  if (fileInput.value) fileInput.value.value = null;
  uploadedFiles.value = [];
  selectedOrganization.value = undefined;
  addDocumentsFromFilesPending.value = false;
  showDialog.value = false;
}

function dragenter(event: DragEvent) {
  event.preventDefault();
  hover.value = true;
}

function dragleave(event: DragEvent) {
  event.preventDefault();
  hover.value = false;
}

function dragover(event: DragEvent) {
  event.preventDefault();
  hover.value = true;
}

function upload(event: Event) {
  const input = event.target as HTMLInputElement;
  const files = input?.files;
  // no selected file
  if (files && !files[0]) {
    return;
  }

  // selected files
  if (files) {
    uploadStarted.value = false;
    const filesArray: File[] = Array.from(files);
    filesArray?.forEach((file) => {
      let status = UploadStatus.READY;
      if (!constants.SUPPORTED_DOCUMENT_TYPES.includes(file.type)) {
        status = UploadStatus.UNSUPPORTED;
      }
      uploadedFiles.value.push({
        key: uuid(),
        file: file,
        isTooLarge: isTooLarge(file),
        status: status
      });
    });
  }

  fileInput.value.value = null;
}

async function drop(event: DragEvent) {
  event.preventDefault();
  hover.value = false;
  uploadStarted.value = false;
  const entries = await getAllFileEntries(event);
  entries.files.forEach((file) => {
    let status = UploadStatus.READY;
    if (
      file.file?.type &&
      !constants.SUPPORTED_DOCUMENT_TYPES.includes(file.file?.type)
    ) {
      status = UploadStatus.UNSUPPORTED;
    }
    uploadedFiles.value.push({
      key: uuid(),
      file: file.file ? file.file : undefined,
      isTooLarge: file.file ? isTooLarge(file.file) : true,
      status: status
    });
  });
}

function removeFile(file: FileType) {
  uploadedFiles.value = uploadedFiles.value.filter((uf) => uf.key !== file.key);
  if (uploadedFiles.value.length === 0) {
    uploadStarted.value = false;
  }
}

async function createDocuments() {
  if (filteredOrganizations.value.length === 0) {
    goToOnboarding();
  } else {
    addDocumentsFromFilesPending.value = true;
    uploadStarted.value = true;
    const promises = [];

    for (const uploadedFile of uploadedFiles.value) {
      uploadedFile.status = UploadStatus.PROGRESS;
      if (uploadedFile.file && organizationId.value) {
        const createFn =
          document_action.value === 0
            ? documentStore.createDocumentFromFile
            : documentStore.createSealDocumentFromFile;

        promises.push(
          createFn(
            uploadedFile.file,
            organizationId.value,
            companySettings.value
          )
            .then(() => {
              uploadedFile.status = UploadStatus.UPLOADED;
            })
            .catch((error) => {
              uploadedFile.status = UploadStatus.ERROR;
              if (
                error.response.data.messageId &&
                constants.DEEPSIGN_API_HANDLED_ERRORS.includes(
                  error.response?.data?.messageId
                )
              ) {
                uploadedFile.apiError = error.response.data.messageId;
              } else {
                if (error.response.data.message)
                  uploadedFile.apiErrorMessage = error.response.data.message;
                console.error('Error creating document', error);
              }
            })
        );
      }
    }

    try {
      await Promise.all(promises);

      if (allFilesSuccessfullyUploaded.value) {
        if (successfullyUploadedFilesCount.value > 1) {
          router.push({
            query: {
              ...route.query,
              filterDocumentStatus:
                document_action.value === 1 ? ['in-progress'] : ['draft'],
              filterSignStatus:
                document_action.value === 1 ? ['pending'] : undefined
            }
          });
          messageStore.showMessage({
            text: i18n.t('snackbars.documents_created', {
              number: uploadedFiles.value.filter(
                (file) => file.status === UploadStatus.UPLOADED
              ).length
            }),
            color: 'success',
            icon: 'fa fa-circle-check'
          });
        } else {
          // Navigate to the specific document page if only 1 file was uploaded
          const documentId = documentStore.document?.documentId;
          if (documentId) {
            await router.push({
              name:
                document_action.value === 0
                  ? 'document-create'
                  : 'document-seal',
              params: { document: documentId }
            });
          }
        }

        closeDialog();
      } else if (uploadedFilesHaveError.value) {
        return;
      }
    } catch (error) {
      console.error('Error uploading files', error);
    } finally {
      uploadStarted.value = false;
      addDocumentsFromFilesPending.value = false;
      emit('done');
    }
  }
}

function goToOnboarding() {
  let url;
  if (
    deepAdminStore.userInfo?.companies.length === 1 &&
    deepAdminStore.userInfo?.companies[0].structure === 'personal'
  ) {
    url = `${
      import.meta.env.VITE_ONBOARDING_FRONTEND_BASE_URL
    }account?service=deepsign`;
  } else {
    if (!selectedOrganization.value) {
      selectedOrganization.value = organizations.value[0];
    }
    if (selectedOrganization.value) {
      url = `${
        import.meta.env.VITE_ONBOARDING_FRONTEND_BASE_URL
      }account/verify/${selectedOrganization.value.group_id}`;
    }
  }

  window.open(url, '_self');
}

function isTooLarge(file: File) {
  return Number((file.size / 1000000).toFixed(1)) > maxFileSizeMB.value;
}

function onCardClick() {
  if (uploadedFilesHaveError.value) return;
  fileInput.value.click();
}

async function onOrgSelect(event: { group_id?: string }) {
  isSealsLoading.value = true;
  seals.value = [];
  companySettings.value = undefined; // initialize companySettings before fetching
  try {
    if (event.group_id) {
      const sealsResponse = await getCompanySeals({
        filterCompanyId: event.group_id
      });
      seals.value = sealsResponse.data;
    } else {
      seals.value = [];
    }
  } catch (e) {
    seals.value = [];
    return e;
  } finally {
    isSealsLoading.value = false;
  }
}

watchEffect(async () => {
  if (!selectedOrganization.value) seals.value = [];
  if (filteredOrganizationsWithoutHeaders.value.length === 1) {
    isSealsLoading.value = true;
    seals.value = [];
    try {
      const response = await getCompanySeals({
        filterCompanyId: filteredOrganizationsWithoutHeaders.value.group_id
      });
      seals.value = response.data;
    } catch (e) {
      seals.value = [];
      return e;
    } finally {
      isSealsLoading.value = false;
    }
  }
});

watch(
  () => canSeal.value,
  (newValue) => {
    if (!newValue) {
      document_action.value = 0;
    }
  },
  {
    immediate: true
  }
);

watch(
  () => defaultOrganization.value,
  async (newOrg) => {
    if (newOrg && newOrg.group_id) {
      const settingsResponse = await getCompanySettings(newOrg.group_id);
      companySettings.value = settingsResponse.data;
    }
  },
  {
    immediate: true,
    deep: true
  }
);
</script>

<style lang="scss" scoped>
@import '@/styles/core/colors';

.document-action {
  @apply flex flex-wrap justify-center items-center w-1/2 transition-colors md:hover:text-primary md:hover:border-primary focus-visible:bg-primary/40 focus-visible:border-primary/20 focus-visible:border-2 focus-visible:border-solid  focus-visible:text-primary border-l-2;
}

// .v-btn-group .v-btn:not(:first-child) {
//   border-inline-start: navy !important;
//   border-inline-start-width: 1px !important;
// }

// .v-btn-toggle .v-btn:not(:first-child) {
//   @apply border; /* Tailwind utility to remove the inline border */
// }

.v-card-actions .v-btn ~ .v-btn:not(.v-btn-toggle .v-btn) {
  margin-inline-start: 0;
  background-color: rgb(245, 245, 245);
}

.dialog-container {
  padding: 60px 82px;
}

.card-title {
  word-break: break-word;
  font-weight: 600;
  font-size: 24px !important;
  text-align: center;
  margin: 0 0 30px;
  white-space: normal;
}

.instructions {
  margin-bottom: 90px;
  text-align: center;
}

.mobile-title {
  margin-left: 0;
  margin-right: 0;
}

.mobile-instructions {
  margin-bottom: 45px;
}

.create-document-dialog {
  overflow-x: hidden;
  border-radius: 12px;
}

.deep-card-title {
  padding: 21px 24px !important;
}

.upload-zone {
  @apply border-gray-400 border border-dashed;
  height: 172px;
  border-radius: 8px;
  max-height: 172px;
  overflow-y: auto;
  overflow-x: hidden;
  &.multiple {
    height: auto;
  }

  &:hover {
    cursor: pointer;
  }

  &.disabled:hover {
    cursor: default;
  }
}

.file-plus-icon {
  margin-top: 24px;
  margin-bottom: 24px;
  font-size: 34px;
  color: $primary-color;
}

.deep-footnote {
  color: $black-text;
  margin-bottom: 8px;

  a {
    text-decoration: none;
  }
}

input.fileInput {
  display: none;
}

.dragover {
  background-color: rgba(93, 74, 198, 0.05);
}

.upload-divider {
  margin-top: 2px;
  min-height: 75%;
}

.hint-message {
  margin-top: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #8e8e93;
}

.deep-callout {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: $black-text;
}

.deepbox-logo {
  margin-top: 2px;
}

.choose-deepbox {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
}

.remove-button {
  &:hover {
    cursor: pointer !important;
    color: $primary-color !important;
  }
}

.unsupported-file-warning {
  margin-top: 30px;
  border-radius: 10px !important;
  height: 52px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.drop-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}
</style>
