<template>
  <v-combobox
    v-bind="$attrs"
    v-model:search="searchQuery"
    type="email"
    autocapitalize="off"
    :autocomplete="identifier"
    :name="identifier"
    autocorrect="off"
    class="bg-white pointer-events-auto hide-icon focus-within:border-none"
    variant="outlined"
    hide-details
    hide-no-data
    hide-selected
    menu-icon=""
    validate-on="blur"
    :label="i18n.t('labels.email')"
    item-title="email"
    item-value="email"
    :items="emailSuggestionList"
    :return-object="false"
    :custom-filter="filterItems"
    data-lpignore="true"
    @focus="onFocus"
    @update:model-value="updateModelValue"
  >
    <template #prepend-inner><slot name="prepend-inner"></slot></template>
    <template #item="{ props, item }">
      <v-list-subheader
        v-if="
          item.raw?.header?.type === 'subheader' &&
          item.raw?.header?.hasEmailMatches
        "
      >
        {{ i18n.t(item.raw?.header?.title) }}
      </v-list-subheader>
      <v-divider v-if="item.value?.type === 'divider'" />
      <v-list-item v-if="item.raw?.email" v-bind="props">
        <template #title>
          <span
            v-for="(segment, index) in parseItemText(item.value, searchQuery)"
            :key="index"
            :class="[segment.class, { 'pointer-events-none': loading }]"
            class="pointer-events-none"
            data-test-id="email-suggestion"
          >
            {{ segment.text }}
          </span>
        </template>
        <template v-if="!item.raw?.isOrgMember" #append>
          <v-btn
            color="default"
            :class="{
              'cursor-not-allowed pointer-events-none': loading
            }"
            class="text-gray-400 mx-1"
            icon="fal fa-xmark"
            size="x-small"
            :aria-label="i18n.t('buttons.remove_signee')"
            @click.stop="onDelete(item.raw?.email)"
          >
            <v-icon>fal fa-xmark</v-icon>
          </v-btn>
        </template>
      </v-list-item>
    </template>
  </v-combobox>
</template>

<script setup lang="ts">
import { v4 as uuid } from 'uuid';
import { useEmailService } from '@/components/inputs/Emailsuggestion/useEmailService';
import { useI18n } from 'vue-i18n';
import { useSuggestionHelper } from '@/components/inputs/Emailsuggestion/useSuggestionHelper';
import { computed, ref, watch } from 'vue';
import { useDebounce } from '@vueuse/core';
import { useRoute } from 'vue-router';
import { useDocumentContext } from '@/composables/useDocumentContext';
const i18n = useI18n();
const emit = defineEmits(['update:modelValue']);
const properties = defineProps({
  emailToHide: {
    type: String,
    default: ''
  }
});

const emailSuggestionList = ref([]);
const loading = ref(false);
const searchQuery = ref('');
const route = useRoute();

const identifier = ref(getUuid());
const { filterItems, parseItemText } = useSuggestionHelper();
const { currentDocument } = useDocumentContext();
const debouncedSearchQuery = useDebounce(searchQuery, 300);
const { getEmailSuggestionList, deleteRecentlyUsedEmails } = useEmailService(
  currentDocument.value?.companyId,
  properties.emailToHide
);

const isDraftMode = computed(() => route.name === 'document-create');

async function onDelete(email: string): Promise<void> {
  await deleteRecentlyUsedEmails(email);
  await getEmailOptions(searchQuery.value);
}
async function getEmailOptions(searchQuery: string) {
  loading.value = true;
  const { isLoading, aggregatedList } =
    await getEmailSuggestionList(searchQuery);
  loading.value = isLoading;
  emailSuggestionList.value = aggregatedList as [];
}

function updateModelValue(value: string | null) {
  if (!value && isDraftMode.value) {
    getEmailOptions('');
  }
  emit('update:modelValue', value);
}

function validateEmail(mail: string) {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})$/.test(
    mail
  );
}

function getUuid() {
  return uuid();
}
function onFocus(e: FocusEvent) {
  if (e && e.target) {
    (e.target as HTMLInputElement).setAttribute('autocomplete', 'off');
  }

  if (
    !isDraftMode.value ||
    validateEmail((e?.target as HTMLInputElement).value)
  ) {
    emailSuggestionList.value = [];
  } else {
    getEmailOptions((e?.target as HTMLInputElement).value);
  }
}

watch(
  debouncedSearchQuery,
  (val) => {
    if (!isDraftMode.value || validateEmail(val)) {
      emailSuggestionList.value = [];
      return;
    }
    getEmailOptions(val);
  },
  {
    deep: true,
    immediate: true
  }
);
</script>
<style lang="scss" scoped>
.initiator :deep(input[type='email']) {
  color: black !important;
  background-color: white !important;
}

.v-combobox--single.initiator:not(.v-combobox--selection-slot)
  :deep(.v-field--dirty:not(.v-field--focused) input) {
  opacity: 1;
}
</style>
