<template>
  <v-card v-if="currentDocument" class="observer-card">
    <div class="flex flex-row rounded-0 items-center">
      <div class="flex items-center justify-center">
        <i class="fas fa-eye text-primary text-lg pe-3" />
      </div>
      <div class="flex-col w-full">
        <div class="observer-title">{{ i18n.t('titles.observer_view') }}</div>
        <div v-if="!isDocumentAuthRoute" class="observer-email">
          {{ userEmail }}
        </div>
        <slot></slot>
      </div>

      <div class="flex-col items-center self-start">
        <DocumentMenu
          ref="documentMenu"
          :document="currentDocument"
          :policy="policy"
          :sign-key="signKey"
          target="parent"
        >
          <template #activator>
            <v-btn
              icon
              data-test-id="signing-card-options"
              class="bg-transparent"
              @click="openDocumentMenu"
            >
              <v-icon class="font-weight-regular">
                fa fa-ellipsis-vertical
              </v-icon>
            </v-btn>
          </template>
        </DocumentMenu>
      </div>
    </div>
    <DocumentDialog />
  </v-card>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { useSignContext } from '@/composables/useSignContext';
import { ref } from 'vue';
import { useDocumentContext } from '@/composables/useDocumentContext';

const { userEmail, policy, signKey } = useSignContext();
const { currentDocument, isDocumentAuthRoute } = useDocumentContext();
const i18n = useI18n();
const documentMenu = ref();
function openDocumentMenu() {
  documentMenu.value.openMenu();
}
</script>

<style scoped lang="scss">
@import '@/styles/core/colors';

.observer-card {
  box-sizing: border-box;
  border-radius: 7px;
  background-color: $grey-background;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15) !important;
  border: 1px solid $grey-border;
  margin-bottom: 32px;
  margin-top: 16px;
  padding: 16px;
  @apply pe-3;
}

.observer-title {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #090909;
}

.observer-email {
  color: $grey-darken-1;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}
</style>
